export const hpBranches = [
  { value: "Hi-Precision Antipolo", label: "Hi-Precision Antipolo" },
  { value: "Hi-Precision Cainta", label: "Hi-Precision Cainta" },
  { value: "Hi-Precision Commonwealth", label: "Hi-Precision Commonwealth" },
  { value: "Hi-Precision Del Monte", label: "Hi-Precision Del Monte" },
  { value: "Hi-Precision East Avenue", label: "Hi-Precision East Avenue" },
  { value: "Hi-Precision Lacson", label: "Hi-Precision Lacson" },
  { value: "Hi-Precision Las Piñas", label: "Hi-Precision Las Piñas" },
  { value: "Hi-Precision Marikina", label: "Hi-Precision Marikina" },
  {
    value: "Hi-Precision N.S. Amoranto (Retiro)",
    label: "Hi-Precision N.S. Amoranto (Retiro)",
  },
  { value: "Hi-Precision Pasig", label: "Hi-Precision Pasig" },
  { value: "Hi-Precision Pioneer", label: "Hi-Precision Pioneer" },
  { value: "Hi-Precision Sucat", label: "Hi-Precision Sucat" },
  { value: "Hi-Precision Taft Avenue", label: "Hi-Precision Taft Avenue" },
  { value: "Hi-Precision T.M. Kalaw", label: "Hi-Precision T.M. Kalaw" },
  { value: "Hi-Precision V. Luna", label: "Hi-Precision V. Luna" },
  { value: "Hi-Precision Valenzuela", label: "Hi-Precision Valenzuela" },
  { value: "Hi-Precision Santa Ana", label: "Hi-Precision Santa Ana" },
  { value: "Hi-Precision Angeles", label: "Hi-Precision Angeles" },
  { value: "Hi-Precision Bacoor", label: "Hi-Precision Bacoor" },
  { value: "Hi-Precision Baguio", label: "Hi-Precision Baguio" },
  { value: "Hi-Precision Baliuag", label: "Hi-Precision Baliuag" },
  { value: "Hi-Precision Cabanatuan", label: "Hi-Precision Cabanatuan" },
  { value: "Hi-Precision Dagupan", label: "Hi-Precision Dagupan" },
  { value: "Hi-Precision Kawit", label: "Hi-Precision Kawit" },
  { value: "Hi-Precision Malolos", label: "Hi-Precision Malolos" },
  { value: "Hi-Precision San Nicolas", label: "Hi-Precision San Nicolas" },
  { value: "Hi-Precision San Fernando", label: "Hi-Precision San Fernando" },
  { value: "Hi-Precision Tarlac", label: "Hi-Precision Tarlac" },
  { value: "Hi-Precision San Jose", label: "Hi-Precision San Jose" },
  { value: "Hi-Precision Plus Alabang", label: "Hi-Precision Plus Alabang" },
  { value: "Hi-Precision Plus Ayala", label: "Hi-Precision Plus Ayala" },
  { value: "Hi-Precision Plus Aura", label: "Hi-Precision Plus Aura" },
  { value: "Hi-Precision Plus BF Homes", label: "Hi-Precision Plus BF Homes" },
  { value: "Hi-Precision Plus Calamba", label: "Hi-Precision Plus Calamba" },
  {
    value: "Hi-Precision Plus Fort Bonifacio",
    label: "Hi-Precision Plus Fort Bonifacio",
  },
  {
    value: "Hi-Precision Plus Katipunan",
    label: "Hi-Precision Plus Katipunan",
  },
  { value: "Hi-Precision Plus Lipa", label: "Hi-Precision Plus Lipa" },
  { value: "Hi-Precision Plus Mckinley", label: "Hi-Precision Plus Mckinley" },
  { value: "Hi-Precision Plus Ortigas", label: "Hi-Precision Plus Ortigas" },
  { value: "Hi-Precision Plus Rockwell", label: "Hi-Precision Plus Rockwell" },
  {
    value: "Hi-Precision Plus Rosario, Batangas",
    label: "Hi-Precision Plus Rosario, Batangas",
  },
  {
    value: "Hi-Precision Plus San Juan (Little Baguio)",
    label: "Hi-Precision Plus San Juan (Little Baguio)",
  },
  {
    value: "Hi-Precision Plus Santa Rosa",
    label: "Hi-Precision Plus Santa Rosa",
  },
  {
    value: "Hi-Precision Plus Vertis North",
    label: "Hi-Precision Plus Vertis North",
  },
  {
    value: "Hi-Precision Plus Dasmariñas",
    label: "Hi-Precision Plus Dasmariñas",
  },
  {
    value: "Hi-Precision Plus Mandaluyong",
    label: "Hi-Precision Plus Mandaluyong",
  },
  { value: "Hi-Precision Butuan", label: "Hi-Precision Butuan" },
  {
    value: "Hi-Precision Cagayan De Oro",
    label: "Hi-Precision Cagayan De Oro",
  },
  { value: "Hi-Precision Davao", label: "Hi-Precision Davao" },
  { value: "Hi-Precision Surigao", label: "Hi-Precision Surigao" },
  {
    value: "Advanced Lab Solutions Centuria",
    label: "Advanced Lab Solutions Centuria",
  },
]

export const schedules = {
  "Hi-Precision Diagnostics Centre": [
    { value: "7:00AM", label: "7:00AM" },
    { value: "7:30AM", label: "7:30AM" },
    { value: "8:00AM", label: "8:00AM" },
    { value: "8:30AM", label: "8:30AM" },
    { value: "9:00AM", label: "9:00AM" },
    { value: "9:30AM", label: "9:30AM" },
    { value: "10:00AM", label: "10:00AM" },
    { value: "10:30AM", label: "10:30AM" },
    { value: "11:00AM", label: "11:00AM" },
    { value: "11:30AM", label: "11:30AM" },
    { value: "12:00PM", label: "12:00PM" },
    { value: "12:30PM", label: "12:30PM" },
    { value: "1:00PM", label: "1:00PM" },
    { value: "1:30PM", label: "1:30PM" },
    { value: "2:00PM", label: "2:00PM" },
    { value: "2:30PM", label: "2:30PM" },
  ],
  "Philippine Heart Center (PHC)": [
    { value: "6:00AM", label: "6:00AM" },
    { value: "6:30AM", label: "6:30AM" },
    { value: "7:00AM", label: "7:00AM" },
    { value: "7:30AM", label: "7:30AM" },
    { value: "8:00AM", label: "8:00AM" },
    { value: "8:30AM", label: "8:30AM" },
    { value: "9:00AM", label: "9:00AM" },
    { value: "9:30AM", label: "9:30AM" },
    { value: "10:00AM", label: "10:00AM" },
    { value: "10:30AM", label: "10:30AM" },
    { value: "11:00AM", label: "11:00AM" },
    { value: "11:30AM", label: "11:30AM" },
    { value: "12:00PM", label: "12:00PM" },
    { value: "12:30PM", label: "12:30PM" },
    { value: "1:00PM", label: "1:00PM" },
    { value: "1:30PM", label: "1:30PM" },
    { value: "2:00PM", label: "2:00PM" },
    { value: "2:30PM", label: "2:30PM" },
    { value: "3:00PM", label: "3:00PM" },
    { value: "3:30PM", label: "3:30PM" },
    { value: "4:00PM", label: "4:00PM" },
    { value: "4:30PM", label: "4:30PM" },
    { value: "5:00PM", label: "5:00PM" },
    { value: "5:30PM", label: "5:30PM" },
  ],
}

export const branchSchedules = [
  { value: "7:00AM", label: "7:00AM" },
  { value: "7:30AM", label: "7:30AM" },
  { value: "8:00AM", label: "8:00AM" },
  { value: "8:30AM", label: "8:30AM" },
  { value: "9:00AM", label: "9:00AM" },
  { value: "9:30AM", label: "9:30AM" },
  { value: "10:00AM", label: "10:00AM" },
  { value: "10:30AM", label: "10:30AM" },
  { value: "11:00AM", label: "11:00AM" },
  { value: "11:30AM", label: "11:30AM" },
  { value: "12:00PM", label: "12:00PM" },
  { value: "12:30PM", label: "12:30PM" },
  { value: "1:00PM", label: "1:00PM" },
  { value: "1:30PM", label: "1:30PM" },
  { value: "2:00PM", label: "2:00PM" },
  { value: "2:30PM", label: "2:30PM" },
]
