import React from "react"

import Section from "elements/Section"
import Message from "elements/Message"

const PackageIncluded = ({ packagesIncluded }) => {
  return (
    <Section title="Diagnostic Test Package" isRequired>
      <p>Tests included:</p>
      <Message className="mt-1 mb-3">
        <div className="content">
          <ul className="mt-0">
            {packagesIncluded?.tests?.map(test => (
              <li className="pb-0">{test}</li>
            ))}
          </ul>
        </div>
      </Message>
    </Section>
  )
}

export default PackageIncluded
