import React, { useContext, useState, useEffect } from "react"
import { Formik, Form } from "formik"
import classNames from "classnames"
import Img from "gatsby-image"
import moment from "moment"

import Section from "elements/Section"
import Message from "elements/Message"
import PackageForm from "./PackageForm"
import FormSelect from "elements/Form/FormSelect"
import ActionButtons from "elements/ActionButtons"

import { navigate } from "gatsby"
import { AppContext } from "../../context/AppContext"
import { useHookImages } from "./hooks/useHookImages"
import styles from "../Elements/utils/elements.module.scss"
import { formValidationSchema } from "./utils/formValidationSchema"

const PackageSelect = ({ packagesIncluded, subtitle }) => {
  const { state, dispatch } = useContext(AppContext)
  const [laboratory, setLaboratory] = useState("")
  const data = useHookImages()

  const handleSelectLaboratory = value => {
    let selectedLaboratory = packagesIncluded?.laboratories?.find(
      lab => lab?.name === value?.value
    )

    dispatch({
      type: "SAVE_PATIENT_ENROLLMENT",
      payload: {
        preferredLaboratory: value,
        availHomePackage: [],
        packageAvailed: {
          testPackage: selectedLaboratory?.tests.join(", "),
          amount: selectedLaboratory?.amount,
          name: selectedLaboratory?.name,
          logo: selectedLaboratory?.logo,
          tests: selectedLaboratory?.tests,
          rawText: selectedLaboratory?.rawText,
          description: selectedLaboratory?.description,
        },
      },
    })

    setLaboratory({
      name: selectedLaboratory?.name,
      logo: selectedLaboratory?.logo,
      description: selectedLaboratory?.description,
    })
  }

  useEffect(() => {
    if (state?.patientEnrollment?.preferredLaboratory?.value) {
      setLaboratory({
        ...state?.patientEnrollment?.packageAvailed,
      })
    }
  }, [
    state?.patientEnrollment?.preferredLaboratory?.value,
    state?.patientEnrollment?.packageAvailed,
  ])

  const handlePackageSubmit = (values, { setErrors }) => {
    let { month, date, year } = values.preferredAppointmentDate
    let preferredDate = moment(
      `${month?.value} ${date?.value} ${year}`
    ).toDate()

    let nextFiveDays = moment()
      .add(4, "days")
      .toDate()
    let nextSixMonths = moment(`Feb 27 2022`).toDate()

    let nextWorkingDay
    let phcDateLimit = moment(`Feb 28 2022`).toDate()
    if (moment().day() === 5 || moment().day() === 6)
      nextWorkingDay = moment().weekday(8)
    else nextWorkingDay = moment().add(1, "days")

    let preferredDateMoment = moment(`${month?.value} ${date?.value} ${year}`)
    if (
      values?.clinicHospital === "Philippine Heart Center (PHC)" &&
      preferredDateMoment.diff(nextWorkingDay, "days") < 0
    ) {
      setErrors({
        preferredAppointmentDate: {
          month: {
            value: "Please enter a valid appointment date.",
          },
          date: {
            value: "Please enter a valid appointment date.",
          },
        },
      })
    } else if (
      values?.preferredLaboratory?.value ===
        "Hi-Precision Diagnostics Centre" &&
      preferredDate < nextFiveDays
    ) {
      setErrors({
        preferredAppointmentDate: {
          month: {
            value: "Please enter a valid appointment date.",
          },
          date: {
            value: "Please enter a valid appointment date.",
          },
        },
      })
    } else {
      navigate("/patient/summary")
      dispatch({
        type: "SAVE_PATIENT_ENROLLMENT",
        payload: {
          sex: values?.sex,
          branch: values?.branch,
          birthday: values?.birthday,
          homeAddress: values?.homeAddress,
          availHomePackage: values?.availHomePackage,
          preferredAppointmentDate: values?.preferredAppointmentDate,
          preferredAppointmentTime: values?.preferredAppointmentTime,
        },
      })
    }
  }

  const isNextDisabled =
    state?.patientEnrollment?.preferredLaboratory?.value === ""
  const isHiPreNextDisabled =
    state?.patientEnrollment?.preferredLaboratory?.value ===
      "Hi-Precision Diagnostics Centre" && state?.documents?.length === 0

  return (
    <Section title="Preferred Laboratory" subtitle={subtitle} isRequired>
      <Formik
        initialValues={{ ...state?.patientEnrollment }}
        validationSchema={formValidationSchema}
        onSubmit={handlePackageSubmit}
      >
        {({ values, onChange, setFieldValue, isValid, submitCount }) => (
          <Form>
            <FormSelect
              name="preferredLaboratory"
              label="Partner Laboratory"
              value={values?.preferredLaboratory}
              options={packagesIncluded?.partnerLaboratories}
              onChange={handleSelectLaboratory}
              isRequired
            />
            <Section title="Laboratory Details" isRequired>
              {values?.preferredLaboratory?.value === "" && (
                <Message>Please select a partner laboratory.</Message>
              )}
              {state?.patientEnrollment?.preferredLaboratory?.value !== "" && (
                <div className="columns ml-1 ml-0-mobile">
                  <div className="column">
                    <div
                      className={classNames(
                        "is-flex pr-1 is-align-items-center is-flex-wrap mt-1-mobile",
                        styles["styledRadio__label"]
                      )}
                    >
                      {laboratory?.logo && (
                        <div className="logo-container mr-3 mr-0-mobile">
                          <Img
                            fixed={data[laboratory.logo].childImageSharp.fixed}
                          />
                        </div>
                      )}
                      <div className="column pl-1">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: laboratory?.description,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Section>
            <PackageForm
              values={values}
              onChange={onChange}
              setFieldValue={setFieldValue}
              selectedPackage={values?.preferredLaboratory?.value}
            />
            {!isValid && submitCount > 0 && (
              <Message color="danger">
                You may have missed some required fields. Please scan through
                the form and check if your information is complete.
              </Message>
            )}
            <ActionButtons
              back={{ label: "Back", link: "/patient/enroll" }}
              submit={{
                label: "Next",
                disabled: isNextDisabled || isHiPreNextDisabled,
              }}
            />
          </Form>
        )}
      </Formik>
    </Section>
  )
}

export default PackageSelect
