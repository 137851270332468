import * as Yup from "yup"
import {
  YEAR_NOW,
  INVALID_YEAR,
  INVALID_EMAIL,
  REQUIRED_MESSAGE,
  INVALID_MOBILE_NUMBER,
} from "services/validations"

export const formValidationSchema = Yup.object().shape({
  birthday: Yup.object().when("preferredLaboratory", {
    is: preferredLaboratory =>
      preferredLaboratory.value === "Hi-Precision Diagnostics Centre",
    then: Yup.object({
      month: Yup.object().shape({
        value: Yup.string()
          .required(REQUIRED_MESSAGE)
          .nullable(),
      }),
      date: Yup.object().shape({
        value: Yup.number()
          .required(REQUIRED_MESSAGE)
          .nullable(),
      }),
      year: Yup.number()
        .min(1900, INVALID_YEAR)
        .max(YEAR_NOW - 1, INVALID_YEAR)
        .required(REQUIRED_MESSAGE),
    }),
  }),
  sex: Yup.string().when("preferredLaboratory", {
    is: preferredLaboratory =>
      preferredLaboratory.value === "Hi-Precision Diagnostics Centre",
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  branch: Yup.object().when("preferredLaboratory", {
    is: preferredLaboratory =>
      preferredLaboratory.value === "Hi-Precision Diagnostics Centre",
    then: Yup.object().shape({
      value: Yup.string()
        .required(REQUIRED_MESSAGE)
        .nullable(),
    }),
  }),
  preferredAppointmentDate: Yup.object({
    month: Yup.object().shape({
      value: Yup.string()
        .required(REQUIRED_MESSAGE)
        .nullable(),
    }),
    date: Yup.object().shape({
      value: Yup.number()
        .required(REQUIRED_MESSAGE)
        .nullable(),
    }),
    year: Yup.number()
      .min(1900, INVALID_YEAR)
      .required(REQUIRED_MESSAGE),
  }),
  preferredAppointmentTime: Yup.object().shape({
    value: Yup.string()
      .required(REQUIRED_MESSAGE)
      .nullable(),
  }),
  homeAddress: Yup.object().when(["preferredLaboratory", "availHomePackage"], {
    is: (preferredLaboratory, availHomePackage) =>
      preferredLaboratory.value === "Hi-Precision Diagnostics Centre" &&
      availHomePackage.length > 0,
    then: Yup.object({
      streetAddress: Yup.string().required(REQUIRED_MESSAGE),
      province: Yup.object().shape({
        value: Yup.string()
          .required(REQUIRED_MESSAGE)
          .nullable(),
      }),
      city: Yup.object().shape({
        value: Yup.string()
          .required(REQUIRED_MESSAGE)
          .nullable(),
      }),
      barangay: Yup.object().shape({
        value: Yup.string()
          .required(REQUIRED_MESSAGE)
          .nullable(),
      }),
    }),
  }),
})
