import React, { Fragment } from "react"

import Address from "elements/Form/Address"
import FormDate from "elements/Form/FormDate"
import FormRadio from "elements/Form/FormRadio"
import FormSelect from "elements/Form/FormSelect"
import FormCheckbox from "elements/Form/FormCheckbox"
import UploadGuidelines from "../Elements/UploadGuidelines"
import RequirementsMessage from "../Elements/UploadDocuments/RequirementsMessage"
import UploadDocumentDropzone from "../Elements/UploadDocuments/UploadDocumentDropzone"

import { hpBranches, schedules } from "./utils/hpBranches"

const PackageForm = ({ selectedPackage, values, onChange, setFieldValue }) => {
  const HOME_PACKAGE_BLURB = "I would like to avail of home service."
  const helper = selectedPackage === "Hi-Precision Diagnostics Centre" ? "Please select a date five days from now up until program end" : ""
  const labRequestMessage = [
    "Patient’s name",
    "Doctor’s name and PRC license number",
    "Kumusta Dok covered tests",
    "Doctor’s reason for prescribing the tests"
  ];
  return (
    <div className="mt-2">
      {selectedPackage === "Hi-Precision Diagnostics Centre" && (
        <Fragment>
          <p className="pb-1">Laboratory Request</p>
          <UploadGuidelines />
          <RequirementsMessage
            header="Please upload a photo of your laboratory request. The laboratory request must include:"
            requirements={labRequestMessage}
          />
          <UploadDocumentDropzone
            docType="lab"
            label="Take a photo or choose a file"
            icon="document"
            maxFiles={6}
          />
        </Fragment>
      )}
      {selectedPackage === "Hi-Precision Diagnostics Centre" && (
        <Fragment>
          <FormDate
            name="birthday"
            label="Birthday"
            values={values?.birthday}
            onChange={onChange}
            isRequired
          />
          <FormRadio
            name="sex"
            value={values?.sex}
            title="Sex"
            options={["Male", "Female"]}
            isRequired
          />
          <FormSelect
            name="branch"
            label="Preferred branch"
            placeholder="Hi-Precision Antipolo"
            value={values?.branch}
            options={hpBranches}
            isRequired
          />
        </Fragment>
      )}
      <FormDate
        name="preferredAppointmentDate"
        label="Preferred appointment date"
        helper={helper}
        values={values?.preferredAppointmentDate}
        onChange={onChange}
        isRequired
      />
      <FormSelect
        name="preferredAppointmentTime"
        label="Preferred appointment time"
        placeholder=""
        value={values?.preferredAppointmentTime}
        options={schedules[selectedPackage]}
        isRequired
      />
      {selectedPackage === "Hi-Precision Diagnostics Centre" && (
        <Fragment>
          <FormCheckbox
            name="availHomePackage"
            values={values?.availHomePackage}
            options={[HOME_PACKAGE_BLURB]}
            optionsHelper="Home service requests are subject to the current lockdown status and Hi-Precision’s coverage."
          />
          {values?.availHomePackage?.length > 0 && (
            <div>
              <Address
                name="homeAddress"
                setFieldValue={setFieldValue}
                values={values}
                fieldNames={["streetAddress", "province", "city", "barangay"]}
                isNationwide
                isRequired
              />
            </div>
          )}{" "}
        </Fragment>
      )}
    </div>
  )
}

export default PackageForm
