import React, { useContext, useEffect, useState } from "react"
import { navigate } from "gatsby"

import Layout from "layout/Layout"
import Container from "layout/Container"
import PackageSelect from "./PackageSelect"
import PackageIncluded from "./PackageIncluded"

import { packages } from "./utils/packages"
import { AppContext } from "../../context/AppContext"

const Package = () => {
  const { state } = useContext(AppContext)
  const [packagesIncluded, setPackagesIncluded] = useState([])
  const [subtitle, setSubtitle] = useState("")

  const PATIENT_ENROLLMENT_STATE = state?.patientEnrollment

  const setPackage = () => {
    let selectedHospital = PATIENT_ENROLLMENT_STATE?.clinicHospital

    selectedHospital === "Philippine Heart Center (PHC)"
      ? setSubtitle("Receive up to Php 1,250 of diagnostic support")
      : setSubtitle("Receive up to TBC of diagnostic support")

    if (selectedHospital)
      setPackagesIncluded(
        packages?.find(
          packageIncluded => packageIncluded?.hospital === selectedHospital
        )
      )
    else navigate("/patient/enroll")
  }

  useEffect(() => {
    setPackage()
  })

  return (
    <Layout
      title="Diagnostic Test Package"
      seoTitle="Select Package"
      subtitle="You must be prescribed with and avail of all tests to be eligible for the diagnostic support. You may only avail of the diagnostic support once."
    >
      <Container isCentered>
        <PackageIncluded packagesIncluded={packagesIncluded} />
        <PackageSelect
          packagesIncluded={packagesIncluded}
          subtitle={subtitle}
        />
      </Container>
    </Layout>
  )
}

export default Package
